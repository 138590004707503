.social.cont {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.social.cont > div {
    width: 47%;
}

.socials-left ul {
    display: flex;
}

.socials-left ul li {
    padding-right: 13px;
}

.socials-left ul li a {
    font-size: 22px;
}

.cpr {
    display: flex;
    flex-wrap: wrap;
}

.cpr > * {
    width: 47%;
    color: #fff;
}

.cpr ul {
    display: flex;
    justify-content: space-around;
}

.cpr ul li a {
    color: #fff;
    font-size: 13px;
}

.copyrights {
    padding: 6px;
    background: #000;
}
  
@media (max-width: 500px) {
    
.social.cont > div {
    width: 85%;
}

.socials-left h2 {
    font-size: 21px;
}

.socials-right form {
    width: 80%;
}

.cpr > * {
    width: 100%;
    text-align: center;
    color: #fff;
}

}
@media (max-width: 400px) {

}