.livechat-cont {
    padding: 10px;
    max-width: 950px;
    width: 80%;
    margin: 0 auto;
    position: relative;
}


.heading-cont {
    background: #000;
    height: 33px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heading-cont a {
    color: var(--pri-col);
    text-align: center;
    display: block;
    font-size: 15px;
    font-weight: 600;
}

.heading-cont > h3 {
    color: #fff;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
}

.messages {
    min-height: 65vh;
    max-height: 65vh;
    overflow-y: scroll;
    display: grid;
    align-items: end;
}

.messages ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.messages ul li {
    width: 70%;
    padding: 9px;
    margin: 6px;
    border: 1px solid #216524;
    border-radius: 6px;
    background: aliceblue;
}

li.msgLeft {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background: #ffffd6 !important;
}

li.msgLeft h6 {
    width: 100%;
}

li span {
    font-size: 10px;
    color: #969696;
}

.messages ul li h6 {
    color: #216524;
}

.livechat-cont form {
    padding: 6px;
    position: absolute;
    bottom: -50px;
    width: 100%;
    /* background: #fff; */
    display: flex;
    justify-content: center;
}

.livechat-cont form input#msg {
    width: 60%;
    height: 33px;
    font-size: 15px;
    margin-right: 21px;
    color: #000;
}

@media ( max-width: 500px){
    .livechat-cont {
        width: 95%;
        padding: 5px;
    }
}