.brochure-card {
    width: 80%;
    height: 250px;
    margin: 30px auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: gainsboro;
    border-radius: 4px;
}

.brochure-card h3 {
    font-size: 30px;
    text-align: center;
}

.brochure-card p {
    text-align: center;
}

.brochure-card a {
    padding: 8px 2px;
    text-align: center;
    background: var(--pri-col);
    border-radius: 4px;
    color: #fff;
}

.call-lines {
    width: 85%;
    margin: 11px auto;
    padding: 4px 18px;
    background: var(--pri-col);
    border-radius: 4px;
}

.call-lines .svg {
    text-align: center;
    height: 100px;
    display: grid;
    justify-items: center;
    align-items: center;
}

.call-lines .svg svg {
    font-size: 90px;
    fill: #fff;
}

.call-lines h3 {
    font-size: 33px;
    color: #fff;
    line-height: 1;
    margin: 15px auto;
}

.call-lines h5 {
    font-size: 18px;
    color: #fff;
    margin: 18px auto;
}

.call-lines a {
    font-size: 11px;
    display: block;
    text-align: center;
    width: 80%;
    padding: 4px;
    margin: 0 auto;
    margin-bottom: 11px;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 4px;
}

.downloads {
    padding: 20px;
    margin-top: 40px;
}

.downloads h3 {
    padding: 21px 0;
    font-size: 27px;
}

.downloads ul li {
    width: 100%;
    display: inline-flex;
    height: 60px;
    align-items: center;
    margin: 8px auto;
}

.downloads ul li svg {
    width: 21%;
    font-size: 33px;
}

.downloads ul li .info a h4 {
    font-size: 15px;
}

.downloads ul li .info a h5 {
    font-size: 13px;
    color: var(--sec-col);
}
