.diff-cont {
    padding: 30px;
    background: var(--sec-col-lite);
    border-radius: 8px;
}

.diff-header {
    width: 13%;
    height: 60%;
    color: var(--sec-col);
    padding-bottom: 60px;
}

.diff-cont ul {
    display: flex;
    justify-content: flex-end;
}

.diff-cont ul li {
    width: 15%;
}

.diff-cont ul li svg {
    font-size: 45px;
    fill: var(--pri-col);
    margin: 13px 0;
}

.diff-cont ul li h5 {
    font-size: 15px;
    color: var(--sec-col);
}

.diff-cont ul li p {
    font-size: 14px;
    width: 80%;
}

.diff-header h2 {
    font-size: 33px;
}

@media(max-width: 500px){
    .diff-cont ul {
        display: flex;
        justify-content: unset;
        flex-direction: column;
    }

    .diff-cont ul li {
        width: 100%;
        padding-bottom: 22px;
    }
}