.loading-cont {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-cont div svg {
    width: 150px;
    height: 150px;
}