.brands {
    width: 100%;
    margin: 0 auto;
    background: #fff;
}

.brands ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.brands ul li {
    width: 30%;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brands ul li svg {
    font-size: 42px;
    fill: gray;
}

.brands ul li h3 {
    color: var(--sec-col);
    font-size: 15px;
}

@media(max-width: 500px){
    .brands ul li {
        width: 40%;
        margin-bottom: 40px;
        justify-content: flex-start;
    }
}