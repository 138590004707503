.applied.mh1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.applied-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.applied-info svg {
    font-size: 60px;
    fill: var(--sec-col);
    padding-bottom: 27px;
    box-sizing: content-box;
}

.applied-info h2 {
    color: var(--sec-col);
}

.applied-cont p {
    padding: 11px 0;
    font-size: 14px;
}

.applied-cont a {
    border: 2px solid;
    width: 65%;
    display: block;
    padding: 4px;
    text-align: center;
    margin: 0 auto;
    color: var(--pri-col);
    border-radius: 4px;
    font-weight: 600;
    margin-top: 54px;
}