.remarks {
    width: 100%;
    margin: auto;
    padding-top: 60px;
    background-image: url('../assets/map.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


.remark-cont {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 60px;
}

.remark-cont h2 {
    font-size: 33px;
}

.remark-cont ul {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.remark-cont ul li {
    width: 18%;
}

.remark-cont ul li h3 {
    color: var(--pri-col);
    font-size: 30px;
    margin: 13px auto;
}

.remark-cont ul li h5 {
    color: grey;
    font-size: 15px;
}

.remark-cont ul li p {
    font-size: 13px;
}

.remark-cont ul li svg {
    font-size: 57px;
    fill: var(--sec-col);
}

@media(max-width: 500px){
    .remark-cont ul li {
        width: 40%;
    }
}