.eta {
  padding: 21px 0;
  border: 1px solid gainsboro;
  border-width: 1px 0;
}

.eta-cont {
  width: 97%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.foot-nav .logo {
  width: 200px;
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.foot-nav .logo a {
  display: flex;
  align-items: center;
}

.foot-nav .logo a h2 {
  line-height: 0;
}

.foot-nav .logo a h2 svg {
  fill: gainsboro;
  font-size: 54px;
}

.foot-nav .logo a h4 {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 1.3;
  color: var(--sec-col);
  font-weight: 400;
}

.foot-nav .logo h5 {
  font-size: 15px;
  font-weight: 400;
  color: var(--sec-col);
}

.eta-cont > div {
  width: 27%;
}

.eta-cont .etas {
  display: flex;
  justify-content: space-evenly;
}

.eta-cont .etas ul h4 {
  color: var(--sec-col);
}

.eta-cont .etas ul li p {
  font-size: 15px;
}

@media (max-width: 500px) {
  .eta-cont {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .foot-nav {
    width: 100% !important;
    display: grid;
    justify-content: center;
  }

  .foot-nav .logo{
    padding-left: 0;
  }

  .etas {
    width: 50% !important;
    padding-top: 21px;
    display: flex;
    flex-direction: column;
  }

  .eta-cont .etas ul li p {
    font-size: 14px;
  }
}
