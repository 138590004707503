.solutions-cont {
	padding: 4px;
}

.solutions-cont ul {
	margin-left: 15px;
	margin-bottom: 15px;
	padding: 11px;
}

.solutions-cont ul li a {
    display: block;
    padding: 4px;
    margin-bottom: 4px;
    border-bottom: 1px dotted var(--pri-col);
}

.solutions-cont ul h3 {
    font-size: 21px;
    margin: 11px 0;
}

