.trust-flcc {
    width: 90%;
    margin: 0 auto;
    height: 75vh;
    display: flex;
    align-items: center;
    padding: 5%;
    background-image: url("../assets/cargo.jpg");
    background-position: center;
    background-size: cover;
}

.trust-flcc-cont {
    width: 35%;
}

.trust-flcc-cont h2 {
    font-size: 40px;
    margin-bottom: 33px;
    color: #fff;
    text-transform: capitalize;
}

.trust-flcc-cont h4 {
    color: #fff;
    font-size: 21px;
}

@media (max-width: 500px) {
  
    .trust-flcc {
      width: 90%;
      margin: 0 auto;
      border-radius: 6px;
    }
  
    .trust-flcc-cont {
      width: 75%;
    }
  
    .trust-flcc-cont h2 {
      font-size: 40px;
      margin-bottom: 28px;
    }
  
    .trust-flcc-cont h4 {
      font-size: 21px;
    }
  }