.home {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 10px;
}

.home-main {
  grid-column: 4/13;
  grid-row: 1/2;
}

.home-side {
  grid-column: 1/4;
	grid-row: 1/2;
}

@media (max-width: 800px) {
}

@media (max-width: 500px) {
  .home-main {
    grid-column: 1/13;
  }

  .home-side {
    grid-column: 1/13;
		grid-row: 2/3;
  }
}
