.dashboard-cont {
  width: 96%;
  margin: 0 auto;
}

.headt {
  padding-top: 3vh;
}

.headt h2 {
  font-size: 18px;
  text-align: center;
  padding: 8px;
  padding-top: 21px;
  color: var(--sec-col);
}

.headt h2 span {
  color: var(--pri-col);
  font-size: 21px;
}

.error-handler {
  padding: 0 10%;
  margin: 6px 0;
}

.error-handler h3 {
  font-size: 13px;
  text-align: center;
  color: #ff0030;
  background: #000;
  border-radius: 4px;
  padding: 2px;
}



.heat-map ul {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  padding-left: 8px;
  background: var(--sec-col-lite);
  border-radius: 8px;
  box-shadow: 1px 1px 4px gray;
  background-image: url("../assets//paperbg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.heat-map > h4 svg {
  font-size: 30px;
  fill: #fff;
  padding-right: 8px;
  box-sizing: content-box;
}

.heat-map > h3 {
  text-align: center;
  font-size: 24px;
  color: var(--sec-col);
  padding-bottom: 15px;
}

.heat-map > h3 svg {
  fill: var(--pri-col);
  font-size: 42px;
  stroke: black;
}

.error-box {
  height: 300px;
  width: 60%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: lavender;
}

.dashboard-cont {
  width: 96%;
  margin: 0 auto;
  padding-bottom: 40px;
}

.heat-map > h4 {
  width: 70%;
  margin: 40px auto;
  background: var(--sec-col);
  padding: 11px;
  border: 1px solid var(--pri-col);
  border-left-width: 21px;
  border-radius: 8px;
  color: #fff;
  font-size: 13px;
}

.heat-map ul li {
  width: 10%;
  position: relative;
}

.heat-map ul li:before {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  height: 6px;
  background: gray;
  right: 110%;
  top: 30px;
  border-radius: 4px;
}

.heat-map ul li h4 {
  font-size: 11px;
  font-weight: 500;
  color: gray;
}

.heat-map ul li h4 span {
  font-size: 13px;
  font-weight: 600;
}

.heat-map ul li h5 {
  font-size: 11px;
  font-weight: 500;
  color: gray;
}

.heat-map ul li h5 span {
  font-size: 13px;
  font-weight: 600;
}

.heat-map ul li p {
  color: gray;
  font-size: 11px;
  font-weight: 500;
}

.heat-map ul li p span {
  font-size: 13px;
  font-weight: 600;
}

.heat-map ul li:first-child:before {
  display: none;
}

li.ar-rived * {
  color: var(--sec-col) !important;
}

li.ar-rived:before {
  background: var(--sec-col) !important;
}

li.on-way * {
  color: var(--pri-col) !important;
}

li.on-way:before {
  background: var(--pri-col) !important;
}

.error-box h2 {
  color: crimson;
  font-size: 21px;
  padding: 30px 0;
}

.error-box svg {
  fill: crimson;
  font-size: 60px;
}

.error-box form {
  display: flex;
  flex-direction: column;
}

.error-box form button {
  color: var(--pri-col);
  padding: 6px;
  border: 1px solid;
  border-radius: 4px;
}

.error-box form input {
  margin-right: 0 !important;
}

.parcel-info {
  padding: 10px;
  padding-top: 30px;
  width: 100%;
  background: var(--sec-col-lite);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.parcel-info h1 {
  background: var(--sec-col);
  color: #fff;
  text-align: center;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 1px #000;
  display: block;
  width: 100%;
}

.parcel-info > div {
  width: 45%;
  padding: 8px;
  margin: 11px;
}

.parcel-info > div h2 {
  font-size: 18px;
}
.parcel-info > div h3 {
  font-weight: 400;
  font-size: 13px;
  color: var(--pri-col);
}

.parcel-info > div h3 span {
  font-weight: 600;
  font-size: 15px;
  color: var(--sec-col);
}

@media (max-width: 500px) {
  .headt h2 {
    font-size: 13px;
    padding-bottom: 30px;
  }
  .headt h2 span {
    font-size: 15px;
  }

  .heat-map ul {
    display: flex;
    flex-direction: column;
  }

  .heat-map ul li {
    width: 80%;
    position: relative;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .heat-map ul li::before {
    right: 0;
  }

  .heat-map ul li {
    width: 80%;
    position: relative;
    margin: 18px auto;
  }

  .heat-map ul li::before {
    right: 0;
    top: 100%;
    margin: 13px auto;
  }

  .heat-map ul li:first-child:before {
    display: block;
  }

  .heat-map ul li h4 {
    font-size: 13px;
}

.heat-map ul li h4 span {
  font-size: 15px;
}

  .heat-map ul li h5 {
    font-size: 13px;
  }
  .heat-map ul li h5 span {
    font-size: 15px;
  }

  .heat-map ul li p {
    font-size: 13px;
  }
  .heat-map ul li p span {
    font-size: 15px;
  }

  .heat-map > h4 {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .heat-map > h4 span {
    padding-top: 13px;
  }

  .error-box {
    height: 60vh;
    width: 85%;
    padding: 11px;
  }

  .error-box h2 {
    color: crimson;
    font-size: 21px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    font-size: 18px;
  }

  .parcel-info > div {
    width: 80%;
    padding: 8px;
    margin: 4px;
  }

  .parcel-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
