.banned * {
    color: #fff;
    position: relative;
}

.banned {
  z-index: 2;
  position: relative;
}

.banned-container ul {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.banned-container ul {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    z-index: 1;
}

.banned-container ul li {
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/sea.jpg");
    background-position: center;
    background-size: cover;
    animation: bnnrChange 30s infinite;
    animation-timing-function: step-start;
    position: relative;
    z-index: -1;
    text-align: center;
}

.banned-container ul li h2{
  padding-top: 30px;
}

@keyframes bnnrChange {
    0%,
    100% {
      background-image: url("../assets/sea.jpg");
    }
    25% {
      background-image: url("../assets/air.jpg");
    }
    50% {
      background-image: url("../assets/lnd.jpg");
    }
    75%{
        background-image: url("../assets/cntnr.jpg");
    }
  }