.news-letter {
    padding: 60px 0;
}

.newsletter-cont {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.newsletter-header {
    grid-column: 1/4;
}

.newsletter-sect {
    grid-column: 4/8;
}

.news-sect {
    grid-column: 8/13;
}

.news-sect form {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.news-sect form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 13px;
    margin-right: 0;
    border-radius: 3px;
    border: 1px solid #767676;
    font-size: 16px;
}

.news-sect form button {
    background: #fff;
    border: 1px solid var(--pri-col);
    padding: 8px;
    border-radius: 3px;
    color: var(--pri-col);
}

.newsletter-sect h4 {
    color: var(--sec-col);
    font-size: 18px;
}

.newsletter-sect p {
    font-size: 13px;
    width: 60%;
}

@media(max-width: 500px){
    .newsletter-header {
        grid-column: 1/13;
        width: 90%;
        margin: 0 auto;
    }

    .newsletter-sect {
        grid-column: 1/13;
        width: 80%;
        margin: 0 auto;
        padding-top: 30px;
    }

    .news-sect {
        grid-column: 1/13;
        width: 70%;
        margin: 0 auto;
        padding-top: 21px;
    }
}