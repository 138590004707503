.about-flcc-cont {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
}

.about-intro {
  grid-column: 1/5;
  grid-row: 1/2;
}

.about-group {
  grid-column: 5/13;
  grid-row: 1/2;
}

.about-intro h1 {
  font-size: 43px;
  padding: 30px 10px;
}

.about-intro h4 {
  font-size: 21px;
  padding: 15px;
  font-weight: 400;
  width: 70%;
}

.about-intro a {
  width: 80%;
  display: block;
  margin: 20px auto;
  text-decoration: underline;
}

.sample-video iframe {
  width: 100%;
  height: 350px;
  position: relative;
  right: 0;
}

.core-values {
  background: #f3fafd;
  margin: 30px;
  padding: 20px;
  width: 70%;
}

.core-list h3 {
  width: 70%;
  padding: 11px;
}

.core-list ul {
  width: 75%;
  margin: 0 auto;
}

.core-list ul li {
  margin: 15px auto;
  display: inline-flex;
}

.core-list ul li svg {
  font-size: 34px;
  width: 40%;
  fill: var(--pri-col);
}

.core-list ul li p {
  font-size: 19px;
}

.core-list > a {
  display: block;
  width: 50%;
  padding: 8px;
  border: 1px solid var(--pri-col);
  margin: 36px auto;
  color: var(--pri-col);
  text-align: center;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
}

@media (max-width: 500px) {
  .about-intro {
    grid-column: 1/13;
    grid-row: 1/2;
  }

  .about-intro h1 {
    font-size: 50px;
    padding: 40px 20px;
    width: 100%;
}

.about-intro h4 {
    font-size: 21px;
    padding: 11px 20px 40px 20px;
    width: 100%;
}

  .about-group {
    grid-column: 1/13;
    grid-row: 2/3;
  }

  .core-values {
    background: #f3fafd;
    padding: 20px 0px;
    margin: 12px;
    width: 100%;
}

  .core-list h3 {
    font-size: 30px;
}

.core-list ul {
  width: 100%;
}

.core-list ul li svg {
  width: 24%;
}



}
