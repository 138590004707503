.trackform{
    position: relative;
    z-index: 3;
}

.trackform form button {
    background: var(--sec-col);
    padding: 8px 34px;
    border: 1px solid transparent;
    border-radius: 6px;
    box-shadow: 1px 1px 0px 1px var(--sec-col-lite);
    color: var(--sec-col-lite);
    font-size: 15px;
    font-weight: 600;
}

.trackform form button:hover {
    box-shadow: 3px 3px 4px -1px var(--sec-col-lite);
}