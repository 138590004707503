.contactCont {
    grid-column: 1/13;
  }
  
  .contactUs {
    visibility: visible;
    background: transparent;
  }
  
  .contactIntro {
    position: relative;
    height: 333px;
    overflow: hidden;
    background: #0a0f1c69;
    backdrop-filter: blur(1px);
    border-radius: 0 0 11px 11px;
}
  
.contactIntro h2 {
    color: #d7f8dc;
    padding: 1%;
    margin-top: 6%;
    text-align: center;
    background: #00040096;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    position: relative;
    z-index: 1;
}
  
  .contactIntro .contactIMG {
    height: 90%;
    width: 35%;
    position: absolute;
    right: 3%;
    top: 11%;
    background-image: url("../assets/ccclient1.png");
    background-position: center;
    background-size: cover;
    animation: bgChange 15s infinite;
    animation-timing-function: step-start;
  }
  
  .contactIntro:after {
    content: "Question";
    color: #addbff;
    font-family: "Rubik Gemstones";
    font-size: 30px;
    padding: 7px;
    width: 30%;
    position: relative;
    top: 20%;
    left: 12%;
    animation: txtChange 15s infinite;
  }
  
  .contactDetails {
    padding: 3% 15%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
  .contactDetails p {
    color: #ebf8ed;
  }
  
  .contactDetails form {
    display: flex;
    width: 80%;
    margin: 3% auto;
    flex-direction: column;
}

.contactDetails form textarea {
    background: transparent;
    width: -webkit-fill-available;
    border-radius: 6px;
    color: #baeaff;
    padding: 5px;
}
  
.contactDetails p {
    color: #d0f0ff;
}
  
  .contactDetails form label {
    padding-top: 10px;
  }
  
  .contactDetails form button {
    grid-column: 2/5;
    width: 20%;
    margin: 3% 1%;
    border-radius: 6px;
    padding: 5px 11px;
    font-family: "Yanone Kaffeesatz";
    font-size: 14px;
    text-align: left;
    background: #0b528b;
    color: #e2f6ff;
    border: 1px solid black;
  }

  .contactDetails form button:hover {
    background: #1678c7;
    color: #f5fcff;
    transition: 500ms ease;
}
  
  .contactDetails form svg {
    fill: #e2f6ff;
  }
  
  .socials {
    background: #0a0f1c69;
    backdrop-filter: blur(1px);
    padding: 2%;
    border-radius: 8px;
}
  
  .socials p {
    font-family: "Chakra Petch";
    color: #c4eafb;
  }
  
  .socials ul {
    display: grid;
  }
  
  .socials ul li {
    width: 40%;
    background: #3b3b3b;
    margin: 1% 2%;
    border-left: 1rem solid #071e4b;
    clip-path: polygon(0 0, 100% 0%, 88% 100%, 0% 100%);
}
  
  .socials ul li:hover {
    background: #071e4b;
    border-left: 2rem solid #0a71c8;
    transition: 400ms;
  }
  
  .socials ul li a {
    display: block;
    width: 100%;
    padding: 1.4em;
    color: #bee2ff;
    font-family: Chakra Petch;
    font-weight: 600;
  }
  
  .socials ul li a:hover,
  .socials ul li a:hover svg {
    color: #dbf3ff;
    transition: 1000ms;
  }
  
  .socials ul li a svg {
    fill: #008dcd;
  }
  
  @keyframes bgChange {
    0%,
    100% {
      background-image: url("../assets/ccclient.png");
    }
    33% {
      background-image: url("../assets/ccclient1.png");
    }
    66% {
      background-image: url("../assets/ccclient2.png");
    }
  }
  
  @keyframes txtChange {
    0%,
    100% {
      content: "Question ?";
      left: 12%;
      opacity: 1;
    }
    29% {
      content: "Question ?";
      left: 11.9%;
      opacity: 1;
    }
    30% {
      content: "Question ?";
      left: -15%;
      opacity: 0;
    }
    32% {
      content: "Query ?";
      left: -14%;
      opacity: 0.1;
    }
    33% {
      content: "Query ?";
      left: 11%;
      opacity: 1;
    }
  
    61% {
      content: "Query ?";
      left: 11%;
      opacity: 1;
    }
    63% {
      content: "Query ?";
      left: -15%;
      opacity: 0;
    }
    64% {
      content: "Enquiry ?";
      left: -14%;
      opacity: 0.1;
    }
    66% {
      content: "Enquiry ?";
      left: 12%;
      opacity: 1;
    }
  
    95% {
      content: "Enquiry ?";
      left: 12%;
    }
    96% {
      content: "Enquiry ?";
      left: 11.9%;
      opacity: 1;
    }
    98% {
      content: "Enquiry ?";
      left: -15%;
      opacity: 0;
    }
    99% {
      content: "Question ?";
      left: -14%;
      opacity: 0.1;
    }
  }
  
  @media (max-width: 800px) {
    .contactIntro h2 {
      font-size: 17px;
      margin-top: 8%;
    }

    .contactIntro .contactIMG {
        top: 10%;
    }
  
    .contactDetails p {
      font-size: 14px;
    }
  
    .contactDetails form label {
      font-size: 13px;
    }
  
    .contactDetails form button {
      width: 21.5%;
    }
  
    .socials p {
      font-size: 13px;
    }
  
    .socials ul li a {
      font-size: 14px;
    }
  }
  
  @media (max-width: 550px) {
    .contactIntro .contactIMG {
      height: 65%;
      width: 60%;
      top: 24%;
      right: 0%;
    }
  
    .contactIntro:after {
      bottom: 0%;
      top: 54%;
      font-size: 24px;
    }

    .contactIntro h2 {
        margin-top: 14%;
    }
  
    .contactDetails {
      padding: 3% 2%;
    }
  
    .contactDetails p {
      font-size: 12px;
    }
  
    .contactDetails form label {
      font-size: 11px;
    }
  
    .contactDetails form button {
      width: 28.5%;
      font-size: 12px;
    }
  
    .socials ul li a {
      font-size: 13px;
    }
  }
  
  @media (max-width: 400px) {
    .contactIntro {
      height: 280px;
    }

    .contactIntro h2 {
        margin-top: 17%;
    }
  }
  
  @media (max-width: 350px) {
    .contactIntro {
      height: 280px;
    }

    .contactIntro h2 {
        margin-top: 19%;
    }
  
    .contactDetails form button {
      width: 37.5%;
  }
  
  .socials ul li {
    width: 55%;
  }
  
  }
  