.qtt {
  padding: 40px 10px;
  background: #fff;
  box-shadow: 1px 2px 6px gray;
  position: relative;
}

.qtt-cont .switch {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
}

.qtt-cont .switch h3 {
  width: 50%;
  text-align: center;
  font-size: 27px;
  padding-bottom: 18px;
  color: var(--sec-col);
  cursor: pointer;
}

.qtt-cont .switch:after {
  content: "";
  display: block;
  width: 50%;
  height: 10px;
  background: var(--pri-col);
  position: absolute;
  z-index: -1;
  bottom: 0%;
  border-radius: 4px;
  z-index: 5;
}

.qtt-cont .switch.right:after {
  left: 50%;
  transition: 500ms ease;
}

.qtt-cont .switch.left:after {
  left: 0;
  transition: 500ms ease;
}

h3.h4clr {
  color: var(--pri-col) !important;
}

.qtt-screen {
  padding: 40px 5px;
}

.reqQuo-cont {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.reqQuo-cont form {
  grid-column: 1/8;
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-around;
}

.reqQuo-cont form select#medium {
  width: 60%;
  padding: 6px;
  font-size: 15px;
  margin-bottom: 15px;
  color: var(--pri-col);
  border: 3px solid var(--sec-col);
  border-radius: 6px;
}

.reqQuo-cont form input {
  width: 45%;
  padding: 8px;
  margin-bottom: 13px;
  margin-right: 13px;
  border-radius: 3px;
  border: 1px solid #767676;
  font-size: 16px;
  height: unset;
}

.reqQuo-cont form button {
  width: 70%;
  padding: 8px;
  font-size: 15px;
  margin: 10px 0;
  color: #fff;
  border: 0;
  border-radius: 4px;
  background: var(--pri-col);
}

.side-foot {
  grid-column: 9/13;
  padding: 8px;
  width: 50%;
  margin: auto;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid var(--sec-col);
}

.side-foot h3 {
  width: 70%;
  margin: 0 auto;
  height: 30%;
}

.side-foot p {
  width: 60%;
}

.track-trace {
  width: 60%;
  padding: 5%;
}


.track-info ul li {
  list-style-type: disc;
  padding-bottom: 15px;
}

.track-info h3 {
  margin-bottom: 30px;
}


.track-info form input {
  padding: 8px;
  margin-right: 13px;
  border-radius: 3px;
  border: 1px solid var(--sec-col);
  font-size: 16px;
}

.track-info form button {
  background: var(--pri-col);
  padding: 6px;
  width: 55%;
  border: navajowhite;
  color: #fff;
  border-radius: 3px;
}

.track-info form input {
  margin-bottom: 15px;
}


.qtt img.modernfam {
  width: 36%;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media(max-width: 500px){
  .reqQuo-cont {
    display: flex;
    flex-direction: column;
}

.reqQuo-cont form input {
  width: 80%;
}

.side-foot p {
  width: 100%;
}

.track-trace {
  width: 90%;
}

.qtt img.modernfam {
  width: 63%;
}
}